import styled from 'styled-components'
import { GridMain } from '@components/Grid/index.style'
import { mq } from '@styles/vars/media-queries.style'
import { Label, Super2 } from '@styles/vars/textStyles.style'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { RoundedImageWrapper } from '@components/RoundedImage/index.style'

export const ImageContentWrapper = styled.div`
  ${clamp('padding-bottom', 100, 110)};
  ${clamp('padding-top', 72, 110)};
  position: relative;
  background: ${props => props.backgroundColor};

  ${props =>
    props.roundedTop &&
    `
    ${clamp('padding-top', 60, 96)};

    &:before {
      background: ${colors.grey};
      ${clamp('border-radius', 12, 24)};
      content: "";
      ${clamp('height', 24, 48)};
      position: absolute;
      ${clamp('top', -12, -24)};
      width: 100%;
      z-index: 2;
    }
  `}

  ${GridMain} {
    ${mq.desk} {
      flex-direction: reverse;
    }
  }

  ${Super2} {
    max-width: 6em;
  }
`

export const ImageWrapper = styled.div`
  padding-bottom: 2.4rem;
  position: relative;
  will-change: transform;

  ${mq.desk} {
    padding-bottom: 0;
  }

  ${props =>
    props.label &&
    `
    ${RoundedImageWrapper} > div {
      &:before {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        content: '';
        left: 0;
        ${clamp('height', 60, 120)};
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
        transform: translateZ(0);
        z-index: 2;
      }
    }
  `}

  ${Label} {
    position: absolute;
    ${clamp('left', 24, 48)};
    ${clamp('top', 20, 44)};
    z-index: 3;
  }
`
